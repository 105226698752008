import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Img from "gatsby-image";
import Container from "./Container";
import breakpoints from "../../styles/breakpoints";
import Section from "./Section";
import BlockContentWithAccordion from "../BlockContentWithAccordion";

const Image = styled(Img)`
  height: 15.625rem;

  &.hidden-mob {
    display: none;
  }

  &.hidden-desk {
    display: block;
  }

  @media only screen and (min-width: ${breakpoints.sm}) {
    height: 18.75rem;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    height: 25rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    height: 31.25rem;

    &.hidden-mob {
      display: block;
    }

    &.hidden-desk {
      display: none;
    }
  }
`;

const Content = styled.div`
  margin: 1.25rem 0 0 0;
  
  p:last-of-type {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0;
  }
  
  h4 {
    margin-top: 0;
    font-weight: normal;
  }

  li {
    list-style: none;
    position: relative;
    margin-bottom: 1rem;
    
    a {
      text-decoration: none;
      color: var(--primary);

      &:hover {
        text-decoration: underline;
      }
    }

    &:before {
      content:"·";
      font-size: 1.4rem;
      line-height: 1rem;
      height: 1rem;
      width: 1rem;
      display: block;
      position: absolute;
      left: -1.25rem;
    }
  }
  
  ul {
    max-width: 30rem;
    margin: 1.75rem auto 1.25rem;
    
    li {
      
      &:first-of-type {
        width: 100%;
        margin-left: -2rem;
        margin-bottom: 1.25rem;
        
        &:before {
          content: '';
        }
      }
    }
  }
  
  ol {
    max-width: 30rem;
    margin: 1.75rem auto 1.25rem;
  }

  @media only screen and (min-width: ${breakpoints.sm}) {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      li {
        width: 50%;
      }
    }
  }
`;

const VerticalImageCopy = (props) => {
    const {copy, image,} = props;

    const serializers = {
      marks: {
        link: ({mark, children}) => {
          const { href } = mark
          return <a href={href} target="_blank" rel="noopener">{children}</a>
        },
        id: ({mark, children}) => {
          const { id } = mark
          return <span id={id}>{children}</span>
        },
      }
    }

    return (
      <Section>
        <Image fluid={image.image.asset.fluid} alt={image.alt} className="hidden-desk"/>
        <Container>
          <Row>
            <Col md={12}>
              <Image fluid={image.image.asset.fluid} alt={image.alt} className="hidden-mob"/>
            </Col>
            <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
              <Content>
                <BlockContentWithAccordion {...copy} serializers={serializers} hasImage={image}/>
              </Content>
            </Col>
          </Row>
        </Container>
      </Section>
    );
};

export default VerticalImageCopy;